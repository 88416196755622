/** imports */
import * as React from 'react'

import { InputWithEnter }  from 'tsRoot/components/form/inputWithEnter'

interface Props {
  inputDivClass?: string
  inputClass?: string
  buttonClass?: string
  placeholder?: string
  buttonText?: string
}

/** エクスポートするJSXのコンポーネント */
export const Main = (props: Props) => {
  const pageName = 'ServiceTop'
  const [search, setSearch] = React.useState('')

  const toProjectsWithSearchParam = () => {
    const params = new URLSearchParams({keywords: search}).toString()

    location.href = `/projects?${params}`
  }

  React.useEffect(() => {
    document.cookie.split(';').forEach(function(value) {
      const data = value.split('=')

      // @ts-ignore
      if (data[0].trim() == `${pageName}-search`) {
        setSearch(decodeURIComponent(data[1]))
      }
    })
  }, [])

  // useEffectのReactDOMでviewの一部分だけ書き換えるようにreactを使っているため、ここではreturnを空で返す
  return (
    <>
      <div className={props.inputDivClass}>
        <InputWithEnter
          className={props.inputClass || ''}
          pageName={pageName}
          inputName={"search"}
          type="text"
          errorCheckEnable={false}
          placeholder={props.placeholder || ''}
          errorMessageClass={''}
          errorFlug={''}
          validationRule={{}}
          errorCheck={() => {}}
          setState={setSearch}
          submit={toProjectsWithSearchParam}
        />
      </div>
      <button
        className={props.buttonClass}
        aria-label="検索ボタン"
        onClick={() => toProjectsWithSearchParam()}
        type="submit"
      >
        <span><span className="md:u-hidden">{props.buttonText}</span></span>
      </button>
    </>
  );
}


