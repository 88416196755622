/*****************************************************************************************
 * エントリーポイントファイル
 *
 * 新規のエントリーポイントの追加時にはwebpackの再起動が必要になるので注意
 ****************************************************************************************/

/** imports */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
// our components
import { Main } from './main'

/** DOMへのrender */
// <div id='projectSearch'/> のコンポーネントが必要なことに注意
const rootElement = document.getElementById('projectSearch');
const rootElementData = rootElement?.dataset

if (rootElement && rootElementData) {
  const root = createRoot(rootElement)
  root.render(
    <Main
      inputDivClass={rootElementData.inputdivclass}
      inputClass={rootElementData.inputclass}
      buttonClass={rootElementData.buttonclass}
      placeholder={rootElementData.placeholder}
      buttonText={rootElementData.buttontext}
    />
  )
} else {
  console.warn('トップページのコンテンツ表示用の要素が存在しません')
}